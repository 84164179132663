import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {PARAMETERS} from '../parameters';
import {filter} from 'rxjs/operators';

declare const ga: any; // TODO clean up all Universal Analytics -related code here and in the index.html
declare const gtag: any;

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private readonly enabled: boolean = false;

    constructor(private location: Location, private router: Router) {
        if (PARAMETERS.ga_tracking_id) {
            ga('create', PARAMETERS.ga_tracking_id, 'auto');
            this.enabled = true;
        }
        if (PARAMETERS.ga4_tracking_id) {
            gtag('js', new Date());
            gtag('config', PARAMETERS.ga4_tracking_id);
        }
    }

    trackPageViews() {
        if (this.enabled) {
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(() => {
                    this.trackPageView();
                });
        }
    }

    trackPageView() {
        if (this.enabled) {
            ga('set', 'page', this.location.path(true) || '/');
            ga('send', 'pageView');
        }
    }

    trackEvent(eventCategory: string, eventAction?: string, eventLabel?: string, eventValue?: number, fieldsObject?: any) {
        if (this.enabled) {
            ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue, fieldsObject);
        }
    }
}
